import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2773007728/src/colmena-2024-v2/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2773007728/src/colmena-2024-v2/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2773007728/src/colmena-2024-v2/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2773007728/src/colmena-2024-v2/src/components/AgencyIntro.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2773007728/src/colmena-2024-v2/src/components/BentoBox.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BrandStats"] */ "/codebuild/output/src2773007728/src/colmena-2024-v2/src/components/BrandStats.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2773007728/src/colmena-2024-v2/src/components/HomeServices.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2773007728/src/colmena-2024-v2/src/components/HomeStatement.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2773007728/src/colmena-2024-v2/src/components/RibbonMarquee.tsx");
